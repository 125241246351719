<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                :disabled="!search_type"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="username">เลือกธนาคาร</label>
              <b-form-select
                v-model="search_from"
                :options="fromOPs"
                placeholder="เลือกธนาคาร"
              />
            </div>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-gradient-dark"
              @click="getDeposit()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <!-- <b-form-group
            class="mr-1 mb-md-0"
          >
            <b-button
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              block
              @click="addData"
            >
              เติมเครดิต
            </b-button>
          </b-form-group> -->
          <div>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <!-- <b-form-select
                    v-model="search_type"
                    class="d-inline-block mr-1"
                    :options="search_type_option"
                  />

                  <b-form-input
                    v-model="search_val"
                    :disabled="!search_type"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  /> -->

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="btn-icon"
                    block
                    @click="Export()"
                  >
                    Export
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(before_balance)="data">
          {{ data.item.before_balance ? Commas(data.item.before_balance.toFixed(2)) : 0.00 }}
        </template>
        <template #cell(balance)="data">
          <p class="font-weight-bolder text-success mb-0">
            {{ data.item.balance ? Commas(data.item.balance.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(amount)="data">
          <p class="font-weight-bolder text-warning mb-0">
            {{ data.item.amount ? Commas(data.item.amount.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(bonus)="data">
          {{ data.item.bonus ? data.item.bonus.toFixed(2) : 0 }}
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <template #cell(username)="data">
          <span
            v-if="data.item.blacklist"
            style="color:red"
          >
            {{ data.item.username }}
          </span>
          <span
            v-else-if="data.item.vip"
            style="color: blue"
          >
            {{ data.item.username }}
          </span>
          <span v-else>
            {{ data.item.username }}
          </span>
        </template>
        <template #cell(fullname)="data">
          <span
            v-if="data.item.blacklist"
            style="color:red"
          >
            {{ data.item.fullname }}
          </span>
          <span
            v-else-if="data.item.vip"
            style="color:blue"
          >
            {{ data.item.fullname }}
          </span>
          <span v-else>
            {{ data.item.fullname }}
          </span>
        </template>
        <!-- Column: Detail -->
        <template #cell(detail)="data">
          <div class="text-nowrap">
            <b-button
              v-b-tooltip.hover.v-info
              title="รายละเอียด"
              variant="gradient-info"
              size="sm"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.id}})"
            >
              <i class="fas fa-address-card" />
            </b-button>
            <!-- <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="FileTextIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.id}})"
            /> -->
            <!-- <b-tooltip
              title="History Deposit & Withdraw"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            /> -->
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.status === 'cancel'"
              variant="light-danger"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'waiting'"
              variant="light-warning"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'success'"
              variant="light-success"
            >
              {{ data.item.status }}
            </b-badge>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.status !== 'cancel'"
              variant="light-primary"
              @click="CancelList(data.item)"
            >
              ยกเลิกรายการ
            </b-badge>

          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getDeposit()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getDeposit()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="เติมเครดิต"
        ok-title="เติมเครดิต"
        @ok="submit"
      >
        <b-overlay
          :show="show2"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <validation-observer ref="simpleRules">
            <div class="d-flex">
              <b-form-input
                id="vi-first-name"
                v-model="tel"
                class="col-9 mr-1"
                name="tel"
                type="number"
                placeholder="กรอกเบอร์โทรศัพท์แล้วกดค้นหา"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="col-2.5 btn-icon"
                block
                @click="getUsersSearch()"
              >
                <feather-icon icon="SearchIcon" />
                ค้นหา
              </b-button>
            </div>
            <div>
              <span
                v-if="!member && search === true"
                style="color :red;"
              >
                ไม่พบข้อมูล
              </span>
              <span
                v-if="member && search === true"
                style="color :green;"
              >
                {{ member.name }} {{ member.surname }} : {{ member.username }}
              </span>
            </div>
            <b-form-group
              label="จำนวนเงินที่ฝาก"
              label-for="vi-first-name"
            >
              <validation-provider
                #default="{ errors }"
                name="amount"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <i class="fad fa-donate" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-first-name"
                    v-model="amount"
                    name="amount"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                </b-input-group>
              </validation-provider>
            </b-form-group>

            <div class="row">
              <div class="col">
                <label for="time">เวลา (ใน สลิป)</label>
                <div class="d-flex align-items-center">
                  <b-form-select
                    v-model="time"
                    :options="timeOp1"
                    style="width: 80px"
                  />
                  <span style="margin-left: 5px; margin-right: 5px">:</span>
                  <b-form-select
                    v-model="time2"
                    :options="timeOp2"
                    style="width: 80px"
                  />
                </div>
              </div>
              <div class="col">
                <div>
                  <label for="from">ที่มาของสลิป</label>
                  <div>
                    <b-form-select
                      v-model="from"
                      :options="fromOP"
                    />
                  </div>
                </div>
              </div>
            </div>

          </validation-observer>
        </b-overlay>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment-timezone'
import {
  BBadge,
  // BRow,
  // BCol,
  BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    BBadge,
    // BCol,
    // vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        // { value: 2, text: 'เบอร์โทร' },
      ],
      search_type: null,
      search_val: null,
      emailValue: '',
      time: null,
      time2: null,
      from: null,
      name: '',
      required,
      email,
      show: true,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 20,
      pageOptions: [10, 15, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      timeOp1: [
        { value: '00', text: '00' }, { value: '01', text: '01' },
        { value: '02', text: '02' }, { value: '03', text: '03' },
        { value: '04', text: '04' }, { value: '05', text: '05' },
        { value: '06', text: '06' }, { value: '07', text: '07' },
        { value: '08', text: '08' }, { value: '09', text: '09' },
        { value: '10', text: '10' }, { value: '11', text: '11' },
        { value: '12', text: '12' }, { value: '13', text: '13' },
        { value: '14', text: '14' }, { value: '15', text: '15' },
        { value: '16', text: '16' }, { value: '17', text: '17' },
        { value: '18', text: '18' }, { value: '19', text: '19' },
        { value: '20', text: '20' }, { value: '21', text: '21' },
        { value: '22', text: '22' }, { value: '23', text: '23' },

      ],
      timeOp2: [
        { value: '00', text: '00' }, { value: '01', text: '01' },
        { value: '02', text: '02' }, { value: '03', text: '03' },
        { value: '04', text: '04' }, { value: '05', text: '05' },
        { value: '06', text: '06' }, { value: '07', text: '07' },
        { value: '08', text: '08' }, { value: '09', text: '09' },
        { value: '10', text: '10' }, { value: '11', text: '11' },
        { value: '12', text: '12' }, { value: '13', text: '13' },
        { value: '14', text: '14' }, { value: '15', text: '15' },
        { value: '16', text: '16' }, { value: '17', text: '17' },
        { value: '18', text: '18' }, { value: '19', text: '19' },
        { value: '20', text: '20' }, { value: '21', text: '21' },
        { value: '22', text: '22' }, { value: '23', text: '23' },
        { value: '24', text: '24' }, { value: '25', text: '25' },
        { value: '26', text: '26' }, { value: '27', text: '27' },
        { value: '28', text: '28' }, { value: '29', text: '29' },
        { value: '30', text: '30' }, { value: '31', text: '31' },
        { value: '32', text: '32' }, { value: '33', text: '33' },
        { value: '34', text: '34' }, { value: '35', text: '35' },
        { value: '36', text: '36' }, { value: '37', text: '37' },
        { value: '38', text: '38' }, { value: '39', text: '39' },
        { value: '40', text: '40' }, { value: '41', text: '41' },
        { value: '42', text: '42' }, { value: '43', text: '43' },
        { value: '44', text: '44' }, { value: '45', text: '45' },
        { value: '46', text: '46' }, { value: '47', text: '47' },
        { value: '48', text: '48' }, { value: '49', text: '49' },
        { value: '50', text: '50' }, { value: '51', text: '51' },
        { value: '52', text: '52' }, { value: '53', text: '53' },
        { value: '54', text: '54' }, { value: '55', text: '55' },
        { value: '56', text: '56' }, { value: '57', text: '57' },
        { value: '58', text: '58' }, { value: '59', text: '59' },

      ],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'detail', label: 'รายละเอียดลูกค้า' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // { key: 'fullname', label: 'ชื่อลูกค้า' },
        { key: 'invited_by_name', label: 'ผู้แนะนำ' },
        { key: 'time', label: 'เวลาในสลิป' },
        { key: 'before_balance', label: 'ก่อนฝาก' },
        { key: 'amount', label: 'จำนวนเงินฝาก' },
        { key: 'bonus', label: 'โบนัส' },
        { key: 'balance', label: 'ยอดเครดิตรวม' },
        { key: 'addbyName', label: 'ฝากเงินโดย' },
        { key: 'bank', label: 'ธนาคาร' },
        { key: 'from', label: 'ที่มา' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Seoul')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'Actions' },

      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      show2: false,
      tel: null,
      search: false,
      dateStart: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      fromOPs: [
        { value: null, text: 'ทั้งหมด' },
        { value: 'scb', text: 'ธนาคารไทยพาณิชย์' },
        { value: 'kbank', text: 'ธนาคารกสิกรไทย' },
        { value: 'truewallet', text: 'truewallet' },
      ],
      search_from: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.interval = setInterval(() => {
      // this.getUserslist()
      this.getDeposit()
    }, 30000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getDeposit()
  },
  methods: {
    Export() {
      this.$http
        .get(`AutoDep/export/${this.dateStart}/${this.dateEnd}`, { responseType: 'blob' })
        .then(response => {
          // console.log(response.data)
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(
            new Blob([response.data]),
          )
          link.setAttribute('download', `รายการฝากออโต้ วันที่ ${this.dateStart} ถึง ${this.dateEnd}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => console.log(error))
    },
    CancelList(data) {
      this.$swal({
        title: 'ยืนยันที่จะยกเลิกรายการฝาก ?',
        text: `ของ ${data.fullname ? data.fullname : data.username} จำนวน ${data.amount} วอน หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const obj = {
            data,
          }
          this.$http
            .post('/deposit/cancel', obj)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.getDeposit()
              this.Success('ยกเลิกรายการฝากสำเร็จ')
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    getUsersSearch() {
      this.show2 = true
      const obj = {
        tel: this.tel,
      }
      this.$http
        .post('/users/search', obj)
        .then(response => {
          this.member = response.data
          this.search = true
          this.show2 = false
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getUserslist() {
      this.$http
        .get('/users/list')
        .then(response => {
          this.show = false
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    getDeposit() {
      // let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_type: this.search_type,
        search_val: this.search_val,
        search_from: this.search_from,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/deposit', { params })
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.depositdata = response.data.data
          // this.depositdata.forEach(items => {
          //   this.getAdmin(items.addby, index)
          //   index += 1
          // })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    getAdmin(addby, index) {
      this.$http
        .get(`/admin/show/${addby}`)
        .then(response => {
          this.show = false
          // console.log(response.data.name)
          this.depositdata[index].addby = response.data.name
        })
        .catch(error => console.log(error))
    },
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.time && this.time2) {
            this.show = true
            const formData = {
              username: this.member.username,
              uid: this.member.id,
              amount: this.amount,
              time: `${this.time}:${this.time2}`,
              bank: this.from,
            }
            this.$http
              .post('/deposit/store', formData)
              .then(() => {
                this.show = false
                this.getDeposit()
                this.time = null
                this.time2 = null
                this.member = ''
                this.amount = ''
                this.from = null
                this.Success('เติมเครดิต สำเร็จ')
              })
              .catch(error => {
                this.show = false
                this.SwalError(error.response.data.message)
              })
          } else {
            this.show = false
            this.SwalError('กรุณาใส่ให้ครบทุกช่อง')
          }
        }
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
      this.tel = null
      this.search = false
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
  color: #fff !important;
}
thead, th, tr {
  color: #fff default;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
th {
    color: #fff default;
    background-color: #000 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
